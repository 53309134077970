import { userStore } from '@juicyllama/frontend-core'
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
	{
		name: 'Auth',
		path: '/',
		component: () => import('../layouts/blank.vue'),
		redirect: () => {
			// Replace this with your actual async login check logic
			return userStore.user ? '/dashboard' : '/login'
		},
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import('../pages/auth/login.vue'),
			},
			{
				path: 'passwordless',
				name: 'Passwordless',
				component: () => import('../pages/auth/passwordless.vue'),
			},
			{
				path: 'reset',
				name: 'Reset Password',
				component: () => import('../pages/auth/reset.vue'),
			},
			{
				path: 'downloads',
				name: 'Downloads',
				component: () => import('../pages/downloads.vue'),
			},
		],
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('../layouts/dashboard.vue'),
		children: [
			{
				path: '',
				name: 'Home',
				meta: { requiresAuth: true },
				component: () => import('../pages/map/home.vue'),
			},
			{
				path: '/profile',
				name: 'Profile',
				meta: { requiresAuth: true },
				component: () => import('../pages/profile.vue'),
			},
			{
				path: '/admin',
				name: 'Admin Home',
				meta: { requiresAuth: true },
				component: () => import('../pages/admin/index.vue'),
			},
			{
				path: '/admin/users',
				name: 'User Admin',
				meta: { requiresAuth: true },
				component: () => import('../pages/admin/users.vue'),
			},
		],
	}
]

export default routes
